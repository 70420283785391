@import "../globals";

/*
 * Basic design setup for entire application
 * -----------------------------------------------
 */

body {
  background-color: lighten($secondary-light,32%) !important;
  .MuiPaper-root {
    padding-bottom: 60px;
    margin-bottom: 40px;
    border-radius: 0;
    box-shadow: 1px 1px 3px rgba(black,.15);
  }
  @media (min-width: $break-point-md) {
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1300px;
      height: 100%;
      background: url(./bgtree.svg) no-repeat left bottom;
      background-size: 100% auto;
      opacity: .15;
      content: " ";
    }
    .MuiContainer-root {
        margin-top: 40px;
    }
    .MuiPaper-root {
        margin-bottom: 240px;
    }
  }
}

/*
 * Typography setup and overwrites
 * -----------------------------------------------
 */
 
h3.MuiTypography-h3 {
  color: $primary-main;
  font-weight: 300;
  border-bottom: 1px solid $secondary-light;
  line-height: 1.2;
  margin-bottom: 30px;
  font-size: 36px;
}
h5.MuiTypography-h5 {
  margin: 30px 0 0 0;
}
p.MuiTypography-body1 {
  margin: 10px 0;
}
a.MuiTypography-colorPrimary,
p.MuiTypography-body1 a {
  color: $primary-dark;
  text-decoration: underline;
}