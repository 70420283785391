@import "../../globals";

/*
 * Basic uploader settings
 * -----------------------------------------------
 */

h4 {
  color: $primary-dark;
  svg {
    margin-right: 10px;
  }
}

/*
 * Stepper above upload tools
 * -----------------------------------------------
 */

.bk-upload-stepper {
  .connector-line { // connector-lines (to go)
    border-color: rgba($primary-light,.5);
  }
  .connector-active, .connector-completed {
    .connector-line { // connector-lines (done)
      border-color: $primary-main;
    }
  }
  .bk-stepper-label {
    span[class^="MuiTypography"] {
      font-size: 14px;
      color: darken($primary-dark,10%);
    }
    &.Mui-disabled {
      span[class^="MuiTypography"] {
        color: rgba(black,.4);
      }
      svg { // circle icon
        color: rgba($primary-light, .4);
        text {
          fill: $primary-dark;
        }
      }
    }
    svg text {
      fill: white;
      font-weight: 700;
      font-size: 14px;
    }
  }
}

/*
 * Next/Back buttons below the upload papers
 * -----------------------------------------------
 */

.bk-upload-buttons {
  position: relative;
  padding-top: 30px !important;
  margin-bottom: 60px;
  &:before {
    position: absolute;
    top: 0;
    right: 32px;
    left: 32px;
    height: 1px;
    background: rgba($primary-light,.5);
    content: " ";
  }
  .bk-upload-buttoncontainer {
    margin: 0 16px;
    button {
      min-width: 200px;
      margin-bottom: 10px;
      color: white;
      font-size: 16px;
    }
    p {
      opacity: .5;
    }
  }
}
