@import "../../globals.scss";

/*
 * Dropbox at first upload step
 * -----------------------------------------------
 */

#dropBox {
  box-shadow: 0 0 3px rgba(0,0,0,.2) inset;
  width: 100%;
  height: 200px;
  z-index: 10;
  margin: 0 12px 20px 12px;
  background: lighten($secondary-light,25%);
  text-align: center;
  h6 {
    font-weight: 100;
    font-size: 42px;
    opacity: .5;
  }
  p {
    opacity: .7;
    margin-top: 12px;
  }
  button {
    background: white;
  }
  &:hover {
      cursor: pointer;
  }
  &:after {
    content: attr(data-assistivetext);
  }
  &.forbidden {
    background-color: lightcoral;
  }

  &.dragover {
    background-color: lightgreen;
  }

  &.dropped {
    background-color: limegreen;
  }
}
