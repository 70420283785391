@import "../globals";

.bk-view-consent {
  padding: 20px;
  p {
    width: 100%;
  }
  @media (min-width: $break-point-md) {
    padding: 60px 0;
    &:before {
      display: none;
    }
    p {
      font-size: 16px;
    }
  }

  svg {
    fill: $primary-main;
    transform: scale(2);
  }
}