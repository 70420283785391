@import "../globals";

.bk-upload-view-submit {
  h5 {
    margin-top: 25px;
    color: $primary-dark;
  }

  hr {
    margin: 50px 0 10px 0;
    background-color: $primary-light;
  }

  .navigation-controls {
    > div {
      margin: 0;
      > div {
        justify-content: unset;
        button {
          min-width: 150px;
        }
      }
    }
  }

  .metadata-container {
    svg {
      display: none;
    }
  }
}
