@import "../../globals";

.bk-welcome-view {
  section {
    margin: 60px 0;
    @media (min-width: $break-point-md) {
      margin: 0;
      padding: 60px 0;
      &:before {
        position: absolute;
        top: 0;
        right: 60px;
        left: 60px;
        height: 1px;
        background: $secondary-light;
        content: " ";
      }
    }
  }
  .section-banner {
    margin-top: 0;
    min-height: 280px;
    text-align: center;
    justify-content: center;
    background-image: url(./banner.jpg);
    h3 {
      position: relative;
      display: block;
      color: $primary-dark;
      font-weight: 300;
      font-size: 22px;
      line-height: 1.2;
      padding: 15px 10px;
      background: white;
      border: 0 none;
      max-width: 85%;
      margin: 0 auto;
      border-radius: 20px;
      span {
        font-weight: 500;
      }
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 280px;
      height: 280px;
      margin: -115px 0 0 -140px;
      background: url(./bannerLogo.svg) no-repeat center center;
      background-size: 100% auto;
      content: " ";
    }
    @media (min-width: $break-point-md) {
      min-height: 500px;
      h3 {
        max-width: none;
        white-space: nowrap;
        padding: 15px 30px;
        font-size: 32px;
      }
      &:before {
        width: 340px;
        height: 340px;
        margin: -130px 0 0 -170px;
      }
    }
  }
  .section-goals {
    padding: 20px;
    p {
      width: 100%;
    }
    @media (min-width: $break-point-md) {
      text-align: center;
      padding: 60px 0;
      &:before {
          display: none;
      }
      p {
        font-size: 16px;
        max-width: 70%;
      }
    }
  }
  .section-how-it-works {
    h4 {
      margin-bottom: 35px;
    }
    svg {
      fill: $primary-main;
      transform: scale(1.4);
      margin: 10px 0;
    }
    @media (min-width: $break-point-md) {
      p {
        font-size: 16px;
      }
    }
  }
  .section-credits {
    img {
      width: 200px;
      height: auto;
    }
  }
  footer {
    &:before {
      position: absolute;
      top: 0;
      right: 60px;
      left: 60px;
      height: 1px;
      background: $secondary-light;
      content: " ";
    }
  }
}
