/*
 * Variables for frontend design
 * -----------------------------------------------
 */

// responsive Breakpoints
$break-point-xs: 500px;
$break-point-sm: 768px;
$break-point-md: 1088px;
$break-point-lg: 1200px;

// colors variables from theme
$primary-light: #cae657;
$primary-main: #96b422;
$primary-dark: #648400;
$primary-contrastText: #000000;
$primary-text: #000000;
$secondary-light: #ffe54b;
$secondary-main: #f1b300;
$secondary-dark: #b98400;
$secondary-contrastText: #000000;
$secondary-text: #000000;

/*
 * Basic mixins for multiple use in SCSS files
 * -----------------------------------------------
 */

@mixin transition($transitionValue: all .2s ease-out) {
  -webkit-transition: $transitionValue;
  -moz-transition: $transitionValue;
  -o-transition: $transitionValue;
  transition: $transitionValue;
}

@mixin transform($transformValue) {
  -webkit-transform: $transformValue;
  -moz-transform: $transformValue;
  -ms-transform: $transformValue;
  transform: $transformValue;
}