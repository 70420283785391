@import "../../globals.scss";

/*
 * Toggle Button
 * -----------------------------------------------
 */

.bk-upload-previewtoggle {
  .bk-upload-tooglelabel {
    font-size: 14px;

      color: $secondary-dark;
    &.active {
    font-weight: 700;
    }
  }
}

/*
 * The loading indicator
 * -----------------------------------------------
 */

.bk-upload-loadingindicator {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  padding: 25px 45px;
  background: rgba(white,.95);
  margin: -50px 0 0 -150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  border-radius: 4px;
  > span {
    font-size: 28px;
    font-weight: 300;
    margin-right: 20px;
    color: $primary-main;
  }
}
