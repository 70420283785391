@import "../../globals.scss";

.bk-appbar {
  .bk-toolbar {
    min-height: 65px;
    padding: 0;
  }
  .bk-brand-container {
    flex-grow: 1;
    height: 30px;
    .bk-logo {
      height: 30px;
    }
  }
  .bk-menu-toggle {
      svg {
          fill: white;
      }
  }
  .bk-navlink {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    padding: .5rem .75rem;
    text-decoration: none;
    background-color: transparent;
    svg {
      margin-top: 5px;
    }
    @include transition();
    &:hover, &.is-active {
      background-color: transparent;
      @include transform(scale(1.1));
    }
    &:last-child {
        padding-right: 0;
    }
  }
}

/* The drawer is mounted outside of the normal dom hierarchy. Therefor we have to apply the styles like this. */
.bk-appbar-drawer, .bk-appbar-drawer-inner {
  width: auto;
  margin-top: 64px;
}

.bk-appbar-drawer-inner {
  background: $secondary-light;
  .bk-navlink {
    border-bottom: 1px solid $secondary-main;
    display: block;
    text-decoration: none;
    .bk-navlink-text {
      > span {
        color: inherit;
        font-size: 16px;
        font-weight: 700;
        color: $primary-dark;
      }
    }
  }
}